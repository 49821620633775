import * as React from "react";
import { useState } from "react";
import { Container, FloatingLabel, Form } from "react-bootstrap";
import PageHelmet from "../../components/common/helmet";
import ToolList from "../../components/tools/toolList";

const UrlEncodePage: React.FC = () => {
  const [plainText, setPlainText] = useState("");
  const [encodedText, setEncodedText] = useState("");

  const fixedEncodeURIComponent = (str: string) =>
    encodeURIComponent(str).replace(/[!*]/g, function (c) {
      return "%" + c.charCodeAt(0).toString(16);
    });

  return (
    <>
      <PageHelmet title="URL Encode & Decode" />
      <Container className="pt-3">
        <ToolList />

        <h1>URL Encode</h1>

        <FloatingLabel controlId="plainText" label="Plain Text">
          <Form.Control
            as="textarea"
            placeholder="Plain text"
            style={{ height: "250px" }}
            value={plainText}
            onChange={(e) => {
              setPlainText(e.target.value);
              setEncodedText(fixedEncodeURIComponent(e.target.value));
            }}
          />
        </FloatingLabel>
        <br />
        <FloatingLabel controlId="encodedText" label="Encoded Text">
          <Form.Control
            as="textarea"
            placeholder="Encoded Text"
            style={{ height: "250px" }}
            value={encodedText}
            onChange={(e) => {
              setEncodedText(e.target.value);
              setPlainText(decodeURIComponent(e.target.value));
            }}
          />
        </FloatingLabel>
      </Container>
    </>
  );
};

export default UrlEncodePage;
